<script>
import Vue from 'vue';

export default {
  functional: true,
  render: function (createElement, context) {
    const components = {
      mcrWikiGlossaryLink: () => import('@common/elements/glossary/mcrWikiGlossaryLink'),
      mcrStaticGlossaryLink: () => import('@common/elements/glossary/mcrStaticGlossaryLink'),
      McrLocalGlossaryLink: () => import('./McrLocalGlossaryLink'),
    };
    const rootTag = context.props.tag || 'div';
    const comp = Vue.component(context.props.name, {
      template: `
          <${rootTag}>
            ${context.props.html}
          </${rootTag}>
          `,
      components: components,
    });

    return createElement(comp, context.data, context.children);
  },
};
</script>
